import React from "react";

function Footer() {
  return (

    <div className="footer">
      <footer className="site-footer">
        <div className="container">
          <p className="m-0 text-center">
           &copy; James Cosmin
          </p>
        </div>
      </footer>
    </div>

  );
}

export default Footer;
