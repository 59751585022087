import React from "react";
import $ from 'jquery';
import jQuery from 'jquery';
import Loader from "./Loader";
import Footer from "./Footer";

class Contact extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: true,
            isSent: false,
            contact_description : ''
        };
    }


    fetchUtils(){
        let url = 'https://api.jamescosmin.com/contact-description/';
        fetch(url)
            .then(response => response.json())
            .then(response => {
                // console.log('data : ', response);
                this.setState({
                    contact_description: response.description
                })
                // console.log(typeof this.state.photos)
            }).catch(e => {
                // console.log('ERROR : ', e)
        })
    }

    jsSetup(){
        var today = new Date();
            var dd = today.getDate();
            var mm = today.getMonth()+1;
            var yyyy = today.getFullYear();
            var week = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
            var dayName = week[new Date().getDay()];
            today = mm + '-' + dd + '-' + yyyy + ' ' + dayName;
            $("#date_count").text(today);

        function startTime() {
            var today = new Date();
            var h = today.getHours();
            var m = today.getMinutes();
            var s = today.getSeconds();
            m = checkTime(m);
            s = checkTime(s);
            $('#time_count').text(h + ":" + m + ":" + s);
            setTimeout(startTime, 500);
            // let t = setTimeout(startTime, 500);
      }
      function checkTime(i) {
        if (i < 10) {i = "0" + i}  // add zero in front of numbers < 10
        return i;
      }

      startTime();

      var yearPercentage = document.getElementById('year-percentage');
      var progressBar = document.getElementById('year-progress-bar');

      function getDayOfTheYear() {
      var now = new Date();
      var start = new Date(now.getFullYear(), 0, 0);
      var diff = (now - start) + ((start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 *   1000);
      var oneDay = 1000 * 60 * 60 * 24;
      var day = Math.floor(diff / oneDay);
      return day;
      }

      function getDaysOfAYear() {
      var today = new Date();
      var year = today.getFullYear();
      var isLeapYear = year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
      return isLeapYear ? 366 : 365;
      }

      function getYearPercentage() {
        return (getDayOfTheYear() / getDaysOfAYear()) * 100;
      }

      function render() {
      yearPercentage.innerHTML = 'Year progress: ' + getYearPercentage().toFixed(0) + '%';
      progressBar.value = getYearPercentage();
      }

      function start() {
        render();
      }
      start();

    }

    componentDidMount() {
        this.fetchUtils();
        window.jcDefaultCall();
        document.title = 'Contact';

        try{
            this.jsSetup();
        }
        catch (err){
            // console.log(err);
        }
        this.setState({
            isLoaded: true,
        })
    }


    handleSubmit(event) {
        // console.log('handle submit called;')
        function getCookie(name) {
            var cookieValue = null;
            if (document.cookie && document.cookie !== '') {
                var cookies = document.cookie.split(';');
                for (var i = 0; i < cookies.length; i++) {
                    var cookie = jQuery.trim(cookies[i]);
                    if (cookie.substring(0, name.length + 1) === (name + '=')) {
                        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                        break;
                    }
                }
            }
            return cookieValue;
        }

        event.preventDefault();
        const data = new FormData(event.target);
        var csrftoken = getCookie('csrftoken');
        let response = fetch('https://api.jamescosmin.com/contact/', {
            credentials: 'include',
            method: 'POST',
            mode: 'no-cors',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-CSRFToken': csrftoken
            },
          body: data,
        });
        // console.log(response);
        response
            .then(response => {
                // console.log('data : ', response);
                this.setState({
                    isSent: true
                })

            }).catch(e => {
                // console.log('ERROR : ', e)
            })
    }

    render() {
        if (!this.state.isLoaded){
            return (
                <Loader />
            )
        }
        else {
            if (this.state.isSent){
                return (
                    <Success />
                )
            }
            else{
                return (
                    <Form onChange={this.handleSubmit.bind(this)} contact_description={this.state.contact_description} />
                )
            }
        }
    }
}


class Form extends React.Component{
    render() {
        let onSubmit = this.props.onChange;
        let contact_description = this.props.contact_description;
        return(
            <div className="container">
                <div className="jccard">
                    <h2>Contact</h2>
                    <p>{ contact_description }</p>
                    <form method="post" id="contact_form" onSubmit={onSubmit}>
                        <div className="form-row">
                            <div className="form-group col-md-6 mb-0">
                                <div className="inputgroup">
                      <span style={{width: "100%"}}>
                          <input type="text" name="name" placeholder="Your name" required id="id_name"/>
                      </span>
                                </div>
                            </div>
                            <div className="form-group col-md-6 mb-0">
                                <div className="inputgroup">
                                    <input type="text" name="email" placeholder="Your email" required
                                           id="id_email"/>
                                </div>
                            </div>
                        </div>
                        <div className="inputgroup">
                            <input type="text" name="subject" placeholder="Subject" required
                                   id="id_subject"/>
                        </div>
                        <div className="inputgroup">
                            <textarea name="message" cols="40" rows="10"
                                      placeholder='Your thoughts&#10;&#10002;&#10;"If a man has a talent and cannot use it, he has failed. If he has a talent and only uses half of it, he has partly failed. If he has a talent and learns to use the whole of it, he has gloriously succeeded, and won a satisfaction and triumph few men will know." ― Thomas Wolfe'></textarea>
                        </div>
                        <div className="form-actions">
                            <div className="neubuttonbasic">
                                <button type="submit" name="_submit">Send</button>
                                <i className="lab la-telegram la-lg" style={{color: "#003367"}}></i>
                            </div>
                        </div>
                    </form>
                </div>
                <div id="clock">
                    <p className="date" id="date_count"></p>
                    <p className="time" id="time_count"></p>
                    <p id="year-percentage"></p>
                </div>
                <progress id="year-progress-bar" className="yrperc" value="" max="100"></progress>
                <Footer/>
            </div>
        )
    }
}


class Success extends React.Component{

    render() {
        return(
            <div className="container">
                <div className="jccard">
                    <h2>Contact</h2>
                    <p>Your email has been sent. Expect to hear back from me swiftly. Thank you.</p>
                </div>
            </div>
        )
    }

}


export default Contact;
