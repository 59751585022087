import React, { Component, lazy, Suspense } from "react";
import { // BrowserRouter as Router, 
          Router,
          Route, 
          Switch, 
          useLocation 
        } from "react-router-dom";
import { Navigation, Home, Contact} from "./components";
import { createBrowserHistory } from "history";
import Loader from "./components/Loader";
import ReactGA from "react-ga";
// import InitializeReactGA from "./helper/googleAnalytics";
// import Headshots from "./components/Headshots";
// import Motion from "./components/Motion";
// import MotionDetail from "./components/MotionDetail";
// import Artwork from "./components/Artwork";
// import Music from "./components/Music";
// import DataStories from "./components/DataStories";
// import DataStoriesDetail from "./components/DataStoriesDetail";
// import Photography from "./components/Photography";
const Headshots = lazy (() => import("./components/Headshots"));
const Motion = lazy (() => import("./components/Motion"));
const MotionDetail = lazy (() => import("./components/MotionDetail"));
const Artwork = lazy (() => import("./components/Artwork"));
const Photography = lazy (() => import("./components/Photography"));
const Music = lazy (() => import("./components/Music"));
const DataStories = lazy (() => import("./components/DataStories"));
const DataStoriesDetail = lazy (() => import("./components/DataStoriesDetail"));

const trackingID = "G-K36HCBG11P";
ReactGA.initialize(trackingID)

const history = createBrowserHistory();
history.listen(location => {
  ReactGA.pageview(location.pathname);
});

// function usePageViews() {
// 	const location = useLocation();
// 	useEffect(() => {
// 		InitializeReactGA(ReactGA);
// 		ReactGA.set({ page: location.pathname });
// 		ReactGA.pageview(location.pathname);
// 	}, [location]);
// }
// function usePageViews() {
//     let location = useLocation();
//     useEffect(() => {
//       if (!window.GA_INITIALIZED) {
//         ReactGA.initialize("UA-178592631-1");
//         window.GA_INITIALIZED = true;
//       }
//       ReactGA.set({ page: location.pathname });
//       ReactGA.pageview(location.pathname);
//     }, [location]);  
// }

function App() {
  // usePageViews();
  return (
    <div className="App">
      <Router history={history}>
        <Navigation />
        <Suspense fallback={<Loader/>}>
          <Switch> 
            <Route path="/" exact component={() => <Home />} />
            <Route path="/headshots" exact component={() => <Headshots />} />
            <Route path="/motion/" exact component={() => <Motion />} />
            <Route path="/motion/:post/" exact component={() => <MotionDetail />} />
            <Route path="/Artwork" exact component={() => <Artwork />} />
            <Route path="/music" exact component={() => <Music />} />
            <Route path="/Photography" exact component={() => <Photography />} />
            <Route path="/datastories" exact component={() => <DataStories />} />
            <Route path="/datastories/:year/:month/:day/:post/" exact component={() => <DataStoriesDetail />} />
            <Route path="/contact" exact component={() => <Contact />} />
            <Route render={() =><h1> 404 | page not found, Please check url again or reload page!</h1>} />
          </Switch>
        </Suspense>
      </Router>
    </div>
  );
}
export default App;
