import React from "react";
import { Link, withRouter } from "react-router-dom";

class Navigation extends React.Component{
  componentDidMount() {
      window.activatePastWork();
  }
  render() {
     return (
        <div className="jcNavigation">
          <nav className="navbar navbar-expand-md navbar">
          <button className="navbar-toggler mr-auto custom-toggler" id="hamburger" type="button" data-toggle="collapse"
                  data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                  aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="nav navbar-nav mr-auto">
              <li className="mx-auto"><span className="sr-only">(current)</span><Link to="/" className="nav-link closableNavLink" style={{letterSpacing: "0"}}><i className="fas fa-home"></i></Link></li>
              <div className="darkmode mx-auto">
                <div className="theme-switch">
                  <div className="switch"></div>
                </div>
              </div>
            </ul>
            <ul className="navbar-nav mx-auto">
              <li className="nav-item">
                <Link className="nav-link closableNavLink btn btn-2" to="/headshots/" data-cont="Headshots">Headshots</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link closableNavLink btn btn-2" to="/motion/">Motion</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link closableNavLink btn btn-2" to="/artwork/">Artwork</Link>
              </li>
              <li className="nav-item">
                <a className="nav-link btn btn-2 nav-toggle" target='.nav-toggle'>Past work<i className="las la-angle-right arrowright"></i></a>
              </li>
              <li className="nav-item">
                <Link className="nav-link closableNavLink btn btn-2 nav-toggle child closed" to="/photography/">Photography</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link closableNavLink btn btn-2 nav-toggle child closed" to="/music/">Music</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link closableNavLink btn btn-2 nav-toggle child closed" to="/datastories/">Datastories</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link closableNavLink btn btn-2" to="/contact/">Contact</Link>
              </li>
            </ul>
            <ul className="nav navbar-nav ml-auto social">
              <li>
                <a href="http://imdb.me/jamescosmin" target={'_blank'} rel="noopener noreferrer" className="nav-link">
                  <i className="fab fa-imdb"></i>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/in/~cosmin/" target={'_blank'} rel="noopener noreferrer" className="nav-link">
                  <i className="fab fa-linkedin"></i>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/JamesCosmin" target={'_blank'} rel="noopener noreferrer" className="nav-link">
                  <i className="fab fa-twitter-square"></i>
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}
export default withRouter(Navigation);