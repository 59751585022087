import React from "react";

class Loader extends React.Component{
    render() 
    {
        return(
            // <div className="spinner-box">
            //     <div className="jc-border-1">
            //         <div className="jc-core-1"></div>
            //     </div>
            //     <div className="jc-border-2">
            //         <div className="jc-core-2"></div>
            //     </div>
            // </div>
            <div className="jcLoad">
                <div className="triangle-wrapper">
                    <div className="triangle triangle-1">
                        <svg className="triangle-svg" viewBox="0 0 140 141">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <polygon className="triangle-polygon"  points="70 6 136 138 4 138"></polygon>
                            </g>
                        </svg>
                    </div>
                    <div className="triangle triangle-2">
                    <svg className="triangle-svg" viewBox="0 0 140 141">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <polygon className="triangle-polygon"  points="70 6 136 138 4 138"></polygon>
                            </g>
                        </svg>
                    </div>
                    <div className="triangle triangle-3">
                    <svg className="triangle-svg" viewBox="0 0 140 141">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <polygon className="triangle-polygon"  points="70 6 136 138 4 138"></polygon>
                            </g>
                        </svg>
                    </div>
                    <div className="triangle triangle-4">
                    <svg className="triangle-svg" viewBox="0 0 140 141">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <polygon className="triangle-polygon"  points="70 6 136 138 4 138"></polygon>
                            </g>
                        </svg>
                    </div>
                    <div className="triangle triangle-5">
                    <svg className="triangle-svg" viewBox="0 0 140 141">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <polygon className="triangle-polygon"  points="70 6 136 138 4 138"></polygon>
                            </g>
                        </svg>
                    </div>
                    <p className="triangle-loading" style={{fontWeight: "bold", letterSpacing: ".8rem" }}>Loading</p>
                </div>
            </div>
        )
    }
}

export default Loader;