import React from "react";
import $ from 'jquery';

class Home extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            intro_video_url: '',
            intro_bio : ''
        }
    }
    fetchUtils(){
        //dev
        // let url = 'http://192.168.8.147:8000/intro/';
        // let url = 'http://192.168.18.146:8000/intro/';
        let url = 'https://api.jamescosmin.com/intro/';
        fetch(url)
            .then(response => response.json())
            .then(response => {
                // console.log('data : ', response);
                this.setState({
                    intro_bio: response.bio,
                    intro_video_url: response.intro_file
                })
                // console.log(typeof this.state.photos)
            }).catch(e => {
                // console.log('ERROR : ', e)
        })
    }
    jsSetup(){
        const convertStyle = () => {
            try{
                const height = window.innerHeight;
                document.getElementById("intro").style.height = `${height}px`;
            }
            catch (err){}
        };
        window.addEventListener("resize", convertStyle);
        window.addEventListener("DOMContentLoaded", convertStyle);
        convertStyle();
                    function muteSectionToogle(){
                $('#mute-toggle').find("i").toggleClass("la-volume-up").toggleClass("la-volume-mute");
                // $('#mute-toggle').find("div").toggleClass("ripple-white").toggleClass("ripple-red");
            }
            function toggleMute() {
                var video=document.getElementById("jchero");
                video.muted = !video.muted;
            }
            $('#mute-toggle').on('click', function(){
                muteSectionToogle();
                toggleMute();
            });
            function infoSectionToogle(){
                $('.bio').toggleClass('zoom-fade-enter');
                $('#info-toggle').find("i").toggleClass("la-times-circle").toggleClass("la-info-circle");
                $('#info-toggle').find("div").toggleClass("ripple-red").toggleClass("ripple-white");
                $('#info-toggle').find("div").addClass("ripple-sonar");
                setTimeout(function(){$('#info-toggle').find("div").removeClass("ripple-sonar");}, 300);
            }
            $('#info-toggle').on('click', function(){
                infoSectionToogle();
            });

            $('.bio').on('click', function(){
                if ((this).is(":visible")){
                  infoSectionToogle();
                }
            });
    }
    componentDidMount() {
        this.fetchUtils();
        window.jcDefaultCall();
        this.jsSetup();
    }
    render(){
      return (
        <div id="intro" style={{width: "100%"}}>
      <div className="intro__inner" parallax="" parallax-ratio="0.2" style={{transform: "translate(0px)"}}>
          <div id="splash">
              <div className="splash-container splash-container--video">
                <video className="uploaded" id='jchero' src={this.state.intro_video_url} muted="muted" playsInline="playsinline" autoPlay="autoplay" loop="loop">
                </video>
              </div>
              <div className="overlay"></div>
                  <div className="content">
                    <div className="inner">
                      <h1 className="wfs caps">
                        <div className="animate jcintro">
                          <span>J</span><span>A</span><span>M</span><span>E</span><span>S</span>
                                {' '}
                          <span>C</span><span>O</span><span>S</span><span>M</span><span>I</span><span>N</span>
                        </div>
                      </h1>
                    </div>
                  </div>
              </div>
              <div id="info-toggle">
                <div className="ripple ripple-white"></div>
                  <i className="las la-info-circle"></i>
              </div>
              <div id="info">
                <div className="inner">
                  <div className="bio zoom-fade-enter-active zoom-fade-enter">
                    <div className="jc-bio-section">
                        {this.state.intro_bio}
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div id="mute-toggle">
            <i className="las la-volume-mute"></i>
        </div>
    </div>
      );
    }
}
export default Home;
